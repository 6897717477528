<template>
	<div class="grid">
		<div class="col-12">
			<div v-if="pageLoading">
				<div class="custom-skeleton">
					<div class="card">
                        <div class="flex mb-3">
                            <Skeleton height="8rem" class="mb-1"></Skeleton>
                        </div>
                        <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
                    </div>
				</div>
			</div>
			<div v-else class="card p-2">
				<Toast/>
				<div class="surface-ground border-1 surface-border p-2">
					<div class="flex flex-grow-1 align-items-center justify-content-center my-2">
						<div class="flex flex-grow-1 align-items-center justify-content-start">
							<div class="text-xl font-semibold">Transection</div>
						</div>
					</div>
                    <Divider></Divider>
					<div class="grid">
						<div class="col-12 lg:col-4 md:col-4">
							<div class="field">
								<label for="valid_at">Start Date</label>
								<Calendar
									class="w-full"
									id="valid_at"
									v-model="start_date"
									dateFormat="dd-mm-yy"
									:showButtonBar="true"
									selectionMode="single"
									:showIcon="true"
									placeholder="Select a date"
								/>
							</div>
						</div>
						<div class="col-12 lg:col-4 md:col-4">
							<div class="field">
								<label for="valid_at">End Date</label>
								<Calendar
									class="w-full"
									id="valid_at"
									v-model="end_date"
									dateFormat="dd-mm-yy"
									:showButtonBar="true"
									selectionMode="single"
									:showIcon="true"
									placeholder="Select a date"
								/>
							</div>
						</div>
						<div class="col-12 lg:col-4 lg:my-5">
							<div class="flex flex-wrap ">
								<div class="flex-1 flex align-items-center justify-content-center mx-2">
									<Button label="Clear" icon="pi pi-filter-slash" class="p-button-raised p-button-text w-full" @click="clearFilters"></Button>
								</div>
								<div class="flex-1 flex align-items-center justify-content-center mx-2">
									<Button label="Apply" icon="pi pi-filter" class="p-button-raised p-button-text w-full" @click="getFilteredList"></Button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <DataTable v-if="transectiontList.data != ''" ref="dt" :value="transectiontList.data" dataKey="id"  responsiveLayout="scroll">
					<Column field="id" header="SN" headerStyle="width:8%; min-width:4rem;">
						<template #body="slotProps">
							#00{{slotProps.index + 1}}
						</template>
					</Column>
					<Column field="user" header="User Name" headerStyle="width:20%; min-width:14rem;">
						<template #body="slotProps">
							{{ slotProps.data.user.name }}
						</template>
					</Column>
					<Column field="tour" header="Tour Name" headerStyle="width:25%; min-width:14rem;">
						<template #body="slotProps">
							{{slotProps.data.tour.title}}
						</template>
					</Column>
					<Column field="date" header="Date" headerStyle="width:18%; min-width:10rem;">
						<template #body="slotProps">
							{{ formatDate(slotProps.data.date) }}
						</template>
					</Column>
					<Column field="amount" header="Amount" headerStyle="width:15%; min-width:8rem;">
						<template #body="slotProps">
							৳ {{slotProps.data.amount}}
						</template>
					</Column>
					<Column field="action" header="Actions" headerStyle="width:10%; min-width:7rem;">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-raised p-button-text p-button-rounded mr-2" @click="confirmDetailSelected(slotProps.data)" />
						</template>
					</Column>
					<template #footer>
						<Paginator v-model:first="transectiontList.from" :rows="transectiontList.rows" :totalRecords="transectiontList.total" :rowsPerPageOptions="[10,20,50]" @page="onPage" >
						</Paginator>
					</template>
				</DataTable> -->
				<DataTable v-if="transectiontList.data != ''" ref="dt" :value="transectiontList.data" dataKey="id"  responsiveLayout="scroll">
					<Column field="id" header="SN" headerStyle="width:7%; min-width:4rem;">
						<template #body="slotProps">
							#00{{slotProps.index + 1}}
						</template>
					</Column>
					<Column field="user" header="Traveller Name" headerStyle="width:18%; min-width:11rem;">
						<template #body="slotProps">
							{{ slotProps.data.cus_name ? slotProps.data.cus_name : '-' }}
						</template>
					</Column>
					<Column field="tour" header="Tour Name" headerStyle="width:27%; min-width:16rem;" bodyStyle="overflow: hidden; display: table-cell; max-width: 40ch; text-overflow: ellipsis; white-space: nowrap;">
						<template #body="slotProps">
							<span >{{ slotProps.data.tour.title ? slotProps.data.tour.title : '-' }}</span>
						</template>
					</Column>
					<Column field="date" header="Date" headerStyle="width:16%; min-width:10rem;">
						<template #body="slotProps">
							{{ slotProps.data.pay_time ? formatDate(slotProps.data.pay_time) : '-' }}
						</template>
					</Column>
					<Column field="pay_status" header="Status" headerStyle="width:12%; min-width:7rem;">
						<template #body="slotProps">
							{{ slotProps.data.pay_status ? slotProps.data.pay_status : '-' }}
						</template>
					</Column>
					<Column field="amount" header="Amount" headerStyle="width:12%; min-width:7rem;">
						<template #body="slotProps">
							{{ slotProps.data.amount ? '৳ '+slotProps.data.amount : '-' }}
						</template>
					</Column>
					<Column field="action" header="Actions" headerStyle="width:10%; min-width:6rem;">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-raised p-button-text p-button-rounded mr-2" @click="confirmDetailSelected(slotProps.data)" />
						</template>
					</Column>
					<template #footer>
						<Paginator v-model:first="transectiontList.from" :rows="transectiontList.rows" :totalRecords="transectiontList.total" :rowsPerPageOptions="[10,20,50]" @page="onPage" >
						</Paginator>
					</template>
				</DataTable>

				<template v-else>
					<div class="card">
						<div class="flex align-items-stretch justify-content-center flex-wrap card-container" style="min-height: 200px">
							<div class="flex align-items-center justify-content-center">
								<Message severity="info" :closable="false">No transection found!</Message>
							</div>
						</div>
					</div>
				</template>

				<Dialog v-model:visible="showDialog" :style="{width: '600px'}" header="Transection Details" :modal="true" :closable="false">
					<div class="surface-section">
						<ul class="list-none p-0 m-0">
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">ID</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">#00{{selectedItem.id}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Transection Date</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.pay_time ? formatDate(selectedItem.pay_time) : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Transection Status</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.pay_status ? selectedItem.pay_status : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Transection Method</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.card_type ? selectedItem.card_type : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Tour Name</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.tour.title ? selectedItem.tour.title : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Tour Date</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.tour.start_date ? formatDate(selectedItem.tour.start_date) : '-'}} || {{selectedItem.tour.end_date ? formatDate(selectedItem.tour.end_date) : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Traveller Name</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.booking.traveller_name ? selectedItem.booking.traveller_name : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Traveller Email</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.booking.traveller_email ? selectedItem.booking.traveller_email : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Traveller Mobile</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.booking.traveller_mobile ? selectedItem.booking.traveller_mobile : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Total Price</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">BDT {{selectedItem.booking.total_price ? selectedItem.booking.total_price : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Transection Amount</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">BDT {{selectedItem.amount_original ? selectedItem.amount_original : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Created At</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.created_at ? formatTime(selectedItem.created_at) : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Updated At</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.updated_at ? formatTime(selectedItem.updated_at) : '-'}}</div>
							</li>
						</ul>
					</div>
					<template #footer>
						<Button label="Close" icon="pi pi-times" class="p-button-text" @click="showDialog = false"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from '@vue/reactivity';
import Axios from 'axios';
import moment from 'moment';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import Constant from '../data/constant';

export default {
	setup() {
		const toast = useToast();
		const router = useRouter();
		const route = useRoute();
		const constant =  ref(new Constant());

		const loading = ref(false);
		const pageLoading = ref(true);
		const showDialog = ref(false);

		const transectiontList = ref(null);
		const agencyList = ref(null);
		const selectedItem = ref(null);
		const filters = ref({});
		const keywordListValue = ref();
		const contactValue = ref();
		const roleValue = ref();
		const pageValue = ref(1);
		const paginationValue = ref(10);
		const Header = ref('Create Manager');

        const today = ref();
        const start_date = ref();
        const end_date = ref();
        today.value = new Date();
        end_date.value = today.value;
        start_date.value = new Date(new Date().setDate(today.value.getDate() - 30));

		const getActiveQuerys = () => {
            if(route.query.page != null){
                pageValue.value = route.query.page;
            }
            if(route.query.pagination != null){
                paginationValue.value = parseInt(route.query.pagination);
            }
            if(route.query.start_date != null){
                start_date.value = moment(route.query.start_date).format("YYYY-MM-DD");
            }
            if(route.query.end_date != null){
                end_date.value = moment(route.query.end_date).format("YYYY-MM-DD");
            }
        };
        getActiveQuerys();

		//PAYMENT LIST
		const getTransectionList = () =>{
			Axios
			.get("api/operator/transaction/list",{
				params: {
                    page: pageValue.value,
                    pagination: paginationValue.value,
                    start_date: start_date.value == null ? null : moment(start_date.value).format("YYYY-MM-DD"),
                    end_date: end_date.value == null ? null : moment(end_date.value).format("YYYY-MM-DD"),
                }
			}
			)
			.then((res) =>{
				pageLoading.value = false;
				if (res.data.response == "success") {
					transectiontList.value = res.data.data;
					transectiontList.value.from = transectiontList.value.from-1;
					transectiontList.value.rows = paginationValue.value;

                    console.log(transectiontList.value);
				}
				else{
					console.log(res.data.message);
                    showToast("error", "Error", res.data.message);
				}
			})
			.catch((err) =>{
				console.log(err);
				pageLoading.value = false;
			})
		};
		getTransectionList();


		//OTHER FUNCTIONS
		const showToast = (severity, summary, detail) => {
			toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
		};

		const formatDate = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY");
        };

		const formatTime = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY hh:mm:ss a");
        };

		const formatCurrency = (value) => {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'BDT'});
			return;
		};

		const hideDialog = () => {
            selectedItem.value = null;
		};

		const formatMobileNumber = (number) => {
            return '0' + number.toString().slice(-10);
        };

		//READ
		const confirmDetailSelected = (product) => {
			// console.log(product);
			// selectedItem.value = product;
			
			Axios
			.get("api/operator/transaction/view/" + product.id)
			.then((res) =>{
				pageLoading.value = false;
				if (res.data.response == "success") {
					selectedItem.value = res.data.data;
					selectedItem.value.booking.traveller_mobile = selectedItem.value.booking.traveller_mobile ? formatMobileNumber(selectedItem.value.booking.traveller_mobile) : '';
					console.log(selectedItem.value);
					showDialog.value = true;
				}
				else{
					console.log(res.data.message);
                    showToast("error", "Error", res.data.message);
				}
			})
			.catch((err) =>{
				console.log(err);
				pageLoading.value = false;
			})
		};


		//FILTER
		const getFilteredList = () => {
            pageLoading.value = true;

            var queryParams = {};
            if(start_date.value != null){
                queryParams.start_date = moment(start_date.value).format("YYYY-MM-DD");
            }
            if(end_date.value != null){
                queryParams.end_date = moment(end_date.value).format("YYYY-MM-DD");
            }

            router.replace({ name: 'transaction', query: { ...queryParams } });

            getTransectionList();
        };

        const clearFilters = () => {
            pageLoading.value = true;

            end_date.value = today.value;
            start_date.value = new Date(new Date().setDate(today.value.getDate() - 30));
            router.replace();

            getTransectionList();
        };

		const onPage = (event) => {
			pageValue.value = event.page + 1;
			paginationValue.value = event.rows;
			pageLoading.value = true;

            var queryParams = {};
            if(pageValue.value != null){
                queryParams.page = pageValue.value;
            }
            if(paginationValue.value != null){
                queryParams.pagination = paginationValue.value;
            }
            router.replace({ name: 'transection', query: { ...queryParams } });

            getTransectionList();
		}

		return{
			pageLoading,
			constant,
			showDialog,
			selectedItem,
			filters,
			transectiontList,
			loading,
			formatDate,
			formatCurrency,
			formatTime,
			hideDialog,
			keywordListValue,
			contactValue,
            roleValue,
			confirmDetailSelected,
            agencyList,
			onPage,
			paginationValue,
            getFilteredList,
            clearFilters,
			Header,
            start_date,
            end_date,
			formatMobileNumber
		}
	},
}
</script>

<style scoped lang="scss">
	@import '../assets/demo/badges.scss';
</style>
